import React, { useState } from 'react';
import { Typography, Button, Spin } from 'antd';
import Spreadsheet from 'react-spreadsheet';
import ColumnMappingModal from './ColumnMappingModal';
import { useSelector } from 'react-redux';
import { generateCode } from '../../../functions/helpers';
import { taxRegimes } from '../../../datasets/Fiscal';
import './CustomerValidationStep.css';

const { Title, Paragraph, Text } = Typography;

interface CustomerValidationStepProps {
  onNext: (matchedCustomers: any[]) => void;
  onBack: () => void;
  stripeCustomers: any[]
  setIsLoading: (isLoading: boolean) => void
  isLoading: boolean
}

const CustomerValidationStep: React.FC<CustomerValidationStepProps> = ({
  onNext,
  onBack,
  stripeCustomers,
  setIsLoading,
  isLoading
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [matchedCustomers, setMatchedCustomers] = useState<any[]>([]);
  const { testmode } = useSelector((state: any) => state.data);

  const getTaxRegimeValue = (input: string): string => {
    // First try to find by value
    const byValue = taxRegimes.find(regime => regime.value === input);
    if (byValue) return byValue.value;

    // Then try to find by label
    const byLabel = taxRegimes.find(regime => regime.label === input);
    if (byLabel) return byLabel.value;

    // If no match found, return the original input
    return input;
  };

  const formatData = (customers: any[]) => {
    // If we have matched customers, use those instead of stripe customers
    const dataToDisplay = matchedCustomers.length > 0 ? matchedCustomers : customers;
    
    return dataToDisplay.map((customer) => {
      if (matchedCustomers.length > 0) {
        // Format for matched customers from uploaded file
        return [
          { coordinates: [customer.id, 'id'], value: customer.id || '', readOnly: true },
          { coordinates: [customer.id, 'legal_name'], value: customer.metadata?.legal_name || customer.name || '', readOnly: false },
          { coordinates: [customer.id, 'email'], value: customer.email || '', readOnly: true },
          { coordinates: [customer.id, 'tax_id'], value: customer.metadata?.tax_id || '', readOnly: false },
          { coordinates: [customer.id, 'zip'], value: customer.metadata?.zip || '', readOnly: false },
          { coordinates: [customer.id, 'tax_system'], value: customer.metadata?.tax_system || '', readOnly: false },
          { coordinates: [customer.id, 'livemode'], value: !customer.livemode ? 'sí' : 'no', readOnly: true },
        ];
      } else {
        // Format for original Stripe customers
        return [
          { coordinates: [customer.id, 'id'], value: customer.id || '', readOnly: true },
          { coordinates: [customer.id, 'legal_name'], value: customer.name || '', readOnly: false },
          { coordinates: [customer.id, 'email'], value: customer.email || '', readOnly: true },
          { coordinates: [customer.id, 'tax_id'], value: '', readOnly: false },
          { coordinates: [customer.id, 'zip'], value: customer.address?.zip || '', readOnly: false },
          { coordinates: [customer.id, 'tax_system'], value: '', readOnly: false },
          { coordinates: [customer.id, 'livemode'], value: testmode ? 'si' : 'no', readOnly: true },
        ];
      }
    });
  };

  const handleSpreadsheetChange = (customers: any[]) => {
    const baseData = matchedCustomers.length > 0 ? formatData(matchedCustomers) : formatData(stripeCustomers);

    for (let i = 0; i < customers.length; i++) {
      const customer = customers[i];
      const baseCustomer = baseData[i];
      
      if (!baseCustomer) continue;
      
      for (let j = 0; j < customer.length; j++) {
        const field = customer[j];
        const baseField = baseCustomer[j];
        
        if (field?.value !== baseField?.value && field?.coordinates) {
          // Get the customer ID and field name from coordinates
          const [customerId, fieldName] = field.coordinates;
          
          // Find the customer to update in the original array
          const customerToUpdate = matchedCustomers.length > 0 
            ? matchedCustomers.find(c => c.id === customerId)
            : stripeCustomers.find(c => c.id === customerId);

          if (customerToUpdate) {
            // Initialize metadata if it doesn't exist
            if (!customerToUpdate.metadata) {
              customerToUpdate.metadata = {};
            }

            // Convert tax regime label to value if the field is tax_system
            const fieldValue = fieldName === 'tax_system' 
              ? getTaxRegimeValue(field.value)
              : field.value;

            // Preserve existing metadata and only update the changed field
            const updatedMetadata = {
              ...customerToUpdate.metadata,
              [fieldName]: fieldValue
            };

            // Update the customer with the new metadata
            customerToUpdate.metadata = updatedMetadata;

            // Update the state with the modified array
            if (matchedCustomers.length > 0) {
              setMatchedCustomers([...matchedCustomers]);
            } else {
              // Create a new matched customers array from stripe customers
              setMatchedCustomers([...stripeCustomers]);
            }
          }
        }
      }
    }
  }

  const handleModalConfirm = async (values: any) => {
    setIsLoading(true);
    try {
      const { matchedData, mappings } = values;
      
      // Create a map of email to Stripe customer for quick lookup
      const stripeCustomerMap = new Map(
        stripeCustomers.map(customer => [customer.email, customer])
      );

      // Process uploaded file data
      const enrichedData = matchedData.map((customer: any) => {
        const customerEmail = customer[mappings.email];
        const stripeCustomer = stripeCustomerMap.get(customerEmail);

        if (stripeCustomer) {
          // If we have a matching Stripe customer, enrich their data
          return {
            ...stripeCustomer,
            metadata: {
              ...stripeCustomer.metadata, // Preserve existing metadata
              legal_name: customer[mappings.legal_name],
              tax_system: customer[mappings.tax_system],
              tax_id: customer[mappings.tax_id],
              use: 'G03', // Default value as specified
              zip: customer[mappings.zip]
            }
          };
        } else {
          // If no matching Stripe customer, create a new entry with the file data
          return {
            id: generateCode(10, 'client'), // Generate a new client ID
            email: customerEmail,
            metadata: {
              legal_name: customer[mappings.legal_name],
              tax_system: customer[mappings.tax_system],
              tax_id: customer[mappings.tax_id],
              use: 'G03',
              zip: customer[mappings.zip],
            },
            livemode: !testmode
          };
        }
      });

      // Create a map of processed emails to avoid duplicates
      const processedEmails = new Set(enrichedData.map((customer: any) => customer.email));

      // Add any Stripe customers that weren't in the uploaded file
      const remainingStripeCustomers = stripeCustomers.filter(
        customer => !processedEmails.has(customer.email)
      );

      // Combine both sets of customers
      const finalCustomers = [...enrichedData, ...remainingStripeCustomers];

      setMatchedCustomers(finalCustomers);

    } catch (error) {
      console.error('Error processing column mapping:', error);
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div style={{ maxWidth: '100%' }}>
      <div style={{ marginBottom: '24px' }}>
        <Title level={2}>Valida la información de tus clientes</Title>
        <Paragraph style={{ color: 'rgba(0, 0, 0, 0.65)' }}>
          Confirma que la información de tus clientes sea correcta
        </Paragraph>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '24px',
        }}
      >
        <Button
          onClick={() => setIsModalOpen(true)}
          disabled={isLoading}
          loading={isLoading}
          className="btn-primary"
        >
          Subir información fiscal
        </Button>
      </div>

      <div style={{ 
        border: '1px solid var(--white)',
        borderRadius: '8px',
        height: '300px',
        backgroundColor: 'var(--white)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}>
        {isLoading ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            <Spin size="large" />
            <Text style={{ color: 'rgba(0, 0, 0, 0.45)' }}>Cargando clientes...</Text>
          </div>
        ) : stripeCustomers.length === 0 ? (
          <Text style={{ color: 'rgba(0, 0, 0, 0.45)' }}>No hay clientes nuevos por importar</Text>
        ) : (
          <div className="spreadsheet-container" style={{ width: '100%', height: '100%', overflow: 'auto' }}>
            <Spreadsheet 
              data={formatData(stripeCustomers)}
              columnLabels={[
                'id de stripe', 
                'Nombre/Razón social', 
                'Email', 
                'RFC',
                'Código postal',
                'Régimen fiscal',
                'Prueba'
              ]}
              hideColumnIndicators={false}
              onChange={handleSpreadsheetChange}
            />
          </div>
        )}
      </div>

      <div
        style={{
          marginTop: '48px',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '12px',
        }}
      >
        <Button className="btn-secondary" onClick={onBack}>Anterior</Button>
        <Button
          className="btn-primary"
          onClick={(e) => {
            onNext(matchedCustomers.length > 0 ? matchedCustomers : stripeCustomers);
          }}
          disabled={isLoading}
        >
          Siguiente
        </Button>
      </div>

      <ColumnMappingModal
        open={isModalOpen}
        onCancel={handleModalCancel}
        onConfirm={handleModalConfirm}
        loading={isLoading}
        stripeCustomers={stripeCustomers}
      />
    </div>
  );
};

export default CustomerValidationStep; 