import React, { useState } from 'react';
import { Card } from 'antd';
import { Layout } from 'antd';
import DateSelectionStep from './components/DateSelectionStep';
import DataPreviewStep from './components/DataPreviewStep';
import CustomerValidationStep from './components/CustomerValidationStep';
import InvoiceConfigStep from './components/InvoiceConfigStep';

const { Content } = Layout;

export interface DateRange {
    startDate: number | null;
    endDate: number | null;
}

enum Step {
  DATE_SELECTION,
  DATA_PREVIEW,
  CUSTOMER_VALIDATION,
  INVOICE_CONFIG
}

interface StripeImportWorkflowProps {
    onClose: () => void;
}

const StripeImportWorkflow: React.FC<StripeImportWorkflowProps> = ({ onClose }) => {
  const [currentStep, setCurrentStep] = useState<Step>(Step.DATE_SELECTION);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: null,
    endDate: null
  });
  const [stripePayments, setStripePayments] = useState<any[]>([]);
  const [stripeCustomers, setStripeCustomers] = useState<any[]>([]);
  const [matchedCustomers, setMatchedCustomers] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAPreviousMonth, setIsAPreviousMonth] = useState(false);

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  }; 

  const handleBack = () => {
    if (currentStep === Step.DATE_SELECTION) {
      onClose();
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleCustomerValidationNext = (customers: any[]) => {
    setMatchedCustomers(customers);
    handleNext();
  };

  const renderStep = () => {
    switch (currentStep) {
      case Step.DATE_SELECTION:
        return (
          <DateSelectionStep
            onDateRangeSelect={setDateRange}
            onNext={handleNext}
            onBack={handleBack}
            selectedDateRange={dateRange}
            onStripePaymentsChange={setStripePayments}
            setIsLoading={setIsLoading}
            isAPreviousMonth={isAPreviousMonth}
            setIsAPreviousMonth={setIsAPreviousMonth}
          />
        );
      case Step.DATA_PREVIEW:
        return (
          <DataPreviewStep
            isLoading={isLoading}
            onNext={handleNext}
            onBack={handleBack}
            stripePayments={stripePayments}
            onStripeCustomersChange={setStripeCustomers}
            setIsLoading={setIsLoading}
          />
        );
      case Step.CUSTOMER_VALIDATION:
        return (
          <CustomerValidationStep
            onNext={handleCustomerValidationNext}
            onBack={handleBack}
            stripeCustomers={stripeCustomers}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        );
      case Step.INVOICE_CONFIG:
        return (
          <InvoiceConfigStep
            onBack={handleBack}
            stripePayments={stripePayments}
            matchedCustomers={matchedCustomers}
            onClose={onClose}
            isAPreviousMonth={isAPreviousMonth}
          />
        );
    }
  };

  return (
    <Layout style={{ padding: '24px', backgroundColor: '#fff' }}>
      <Content>
        <Card bordered={false} style={{ maxWidth: '1200px', margin: '0 auto' }}>
          {renderStep()}
        </Card>
      </Content>
    </Layout>
  );
};

export default StripeImportWorkflow; 