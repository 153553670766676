import React from 'react'
import { Typography, DatePicker, Button, Alert } from 'antd'
import type { RangePickerProps } from 'antd/es/date-picker'
import type { DateRange } from '../StripeImportWorkflow'
import dayjs from 'dayjs'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useSelector } from 'react-redux'

const { Title, Text, Paragraph } = Typography
const { RangePicker } = DatePicker

interface DateSelectionStepProps {
    onDateRangeSelect: (range: DateRange) => void
    onNext: () => void
    onBack: () => void
    selectedDateRange: DateRange
    onStripePaymentsChange: (payments: any[]) => void
    setIsLoading: (isLoading: boolean) => void
    isAPreviousMonth: boolean
    setIsAPreviousMonth: (isAPreviousMonth: boolean) => void
}

const DateSelectionStep: React.FC<DateSelectionStepProps> = ({
    onDateRangeSelect,
    onNext,
    onBack,
    selectedDateRange,
    onStripePaymentsChange,
    setIsLoading,
    isAPreviousMonth,
    setIsAPreviousMonth
}) => {
    const { team } = useSelector((state: any) => state.team)
    const { testmode } = useSelector((state: any) => state.data)

    const handleDateRangeChange: RangePickerProps['onChange'] = async (dates) => {
        setIsAPreviousMonth(false)
        setIsLoading(true)
        const startDate: number | null = dates?.[0]?.startOf('day')?.unix() || null
        let endDate: number | null = dates?.[1]?.endOf('day')?.unix() || null
        endDate = endDate && endDate > dayjs().unix() ? dayjs().unix() : endDate

        onDateRangeSelect({
            startDate,
            endDate,
        })

        if (startDate && endDate) {
            if (startDate < dayjs().startOf('month').unix() || endDate < dayjs().startOf('month').unix()) {
                setIsAPreviousMonth(true)
            }
        }

        if (startDate && endDate) {
            const stripeImportsFunction = httpsCallable(getFunctions(), 'stripeimportsapp')
            try {
                const response: any = await stripeImportsFunction({
                    action: 'stripe_payments',
                    teamId: team?.id,
                    dateRange: [startDate, endDate],
                    test: testmode
                })
                const payments = response.data
                onStripePaymentsChange(payments)
            } catch (error) {
                console.error('Error calling stripeImportsFunction:', error)
            } finally {
                setIsLoading(false)
            }
        }
    }

    const isDateRangeSelected = selectedDateRange.startDate && selectedDateRange.endDate

    return (
        <div style={{ maxWidth: '100%' }}>
            <Title level={2}>Configura la importación de recursos</Title>
            <Paragraph style={{ color: 'rgba(0, 0, 0, 0.65)' }}>
                Ya estás conectado con Stripe. Configuremos la exportación de pagos y la generación automática de
                facturas.
            </Paragraph>

            <div style={{ marginTop: '32px' }}>
                <Title level={3}>Fecha de inicio de importación de recursos</Title>
                <Paragraph style={{ color: 'rgba(0, 0, 0, 0.65)' }}>
                    Selecciona el rango de fechas para importar tus pagos y clientes a gigstack. Los pagos procesados
                    fuera de este rango no serán importados.
                </Paragraph>

                <div style={{ marginTop: '24px' }}>
                    <Text>
                        Rango de fechas de importación <span style={{ color: '#ff4d4f' }}>*</span>
                    </Text>
                    <div style={{ marginTop: '8px' }}>
                        <RangePicker
                            style={{ width: '100%' }}
                            placeholder={['Fecha inicial', 'Fecha final']}
                            onChange={handleDateRangeChange}
                            value={[
                                selectedDateRange.startDate ? dayjs.unix(selectedDateRange.startDate) : null,
                                selectedDateRange.endDate ? dayjs.unix(selectedDateRange.endDate) : null,
                            ]}
                        />
                    </div>
                </div>

                {isAPreviousMonth && (
                    <div style={{ marginTop: '24px' }}>
                        <Alert
                            message="El rango seleccionado incluye fechas de meses anteriores. Importaremos los pagos correspondientes, pero no podremos generarles facturas."
                            type="info"
                            style={{
                                fontSize: '12px',
                                fontWeight: 'normal',
                            }}
                        />
                    </div>
                )}

                <div
                    style={{
                        marginTop: '48px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '12px',
                    }}
                >
                    <Button className="btn-secondary" onClick={onBack}>
                        Importar después
                    </Button>
                    <Button className="btn-primary" onClick={onNext} disabled={!isDateRangeSelected}>
                        Siguiente
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default DateSelectionStep
