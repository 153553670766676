import React from 'react'
import { Typography, Button, Spin } from 'antd'
import Spreadsheet from 'react-spreadsheet'
import dayjs from 'dayjs'
import { httpsCallable } from 'firebase/functions'
import { getFunctions } from 'firebase/functions'
import { useSelector } from 'react-redux'
import { paymentForms } from '../../../datasets/Fiscal'

const { Title, Paragraph, Text } = Typography

interface DataPreviewStepProps {
    onNext: () => void
    onBack: () => void
    stripePayments: any[]
    onStripeCustomersChange: (customers: any[]) => void
    setIsLoading: (isLoading: boolean) => void
    isLoading: boolean
}

const DataPreviewStep: React.FC<DataPreviewStepProps> = ({
    onNext,
    onBack,
    stripePayments,
    onStripeCustomersChange,
    isLoading,
    setIsLoading,
}) => {
    const { team } = useSelector((state: any) => state.team)
    const { testmode } = useSelector((state: any) => state.data)

    const getPaymentFormLabel = (code: string): string => {
        const paymentForm = paymentForms.find(form => form.value === code);
        return paymentForm ? paymentForm.label : code;
    };

    const formatData = (payments: any[]) => {
        return payments.map((payment) => [
            { value: payment.id || '', readOnly: true },
            { value: payment.amount ? (payment.amount / 100).toFixed(2) : '', readOnly: true },
            { value: payment.currency?.toUpperCase() || '', readOnly: true },
            { value: payment.status && payment.status === 'succeeded' ? 'Exitoso' : payment.status === 'canceled' ? "Cancelado" : payment.status || '', readOnly: true },
            { value: payment.created ? dayjs.unix(payment.created).format('DD/MM/YYYY') : '', readOnly: true },
            { value: getPaymentFormLabel(payment.payment_method || ''), readOnly: true },
            { value: payment.completed ? dayjs.unix(payment.completed).format('DD/MM/YYYY') : '', readOnly: true },
            { value: payment.amount_refunded || '', readOnly: true },
            { value: payment.livemode ? 'no' : 'sí' , readOnly: true },
        ])
    }

    const handleNext = async () => {
        setIsLoading(true)
        const stripeImportsFunction = httpsCallable(getFunctions(), 'stripeimportsapp')
        try {
            const response: any = await stripeImportsFunction({
                action: 'stripe_clients',
                teamId: team?.id,
                test: testmode,
            })
            const payments = response.data
            onStripeCustomersChange(payments)
        } catch (error) {
            console.error('Error calling stripeImportsFunction:', error)
        } finally {
            setIsLoading(false)
            onNext()
        }
    }

    return (
        <div style={{ maxWidth: '100%' }}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    marginBottom: '24px',
                }}
            >
                <div>
                    <Title level={2}>Revisa los pagos</Title>
                    <Paragraph style={{ color: 'rgba(0, 0, 0, 0.65)', marginBottom: '32px' }}>
                        Ya estás conectado con Stripe. Configuremos la exportación de pagos y la generación automática
                        de facturas.
                    </Paragraph>
                </div>
            </div>

            <div
                style={{
                    border: '1px solid #f0f0f0',
                    borderRadius: '8px',
                    height: '300px',
                    backgroundColor: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                }}
            >
                {isLoading ? (
                    <Spin size="large" />
                ) : stripePayments.length === 0 ? (
                    <Text style={{ color: 'rgba(0, 0, 0, 0.45)' }}>No hay pagos nuevos por importar</Text>
                ) : (
                    <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
                        <Spreadsheet
                            data={formatData(stripePayments)}
                            columnLabels={['Id', 'Total', 'Moneda', 'Estado', 'Fecha', 'Forma de pago', 'Fecha de pago', 'Reembolsos', 'Prueba']}
                            hideColumnIndicators={false}
                            onChange={() => {}}
                        />
                    </div>
                )}
            </div>

            <div
                style={{
                    marginTop: '48px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '12px',
                }}
            >
                <Button className="btn-secondary" onClick={onBack}>Anterior</Button>
                <Button
                    className="btn-primary"
                    onClick={handleNext}
                    disabled={stripePayments.length === 0 || isLoading}
                >
                    Siguiente
                </Button>
            </div>
        </div>
    )
}

export default DataPreviewStep
