import React, { useState, useEffect } from 'react';
import { Modal, Typography, Form, Select, Upload, message, Spin } from 'antd';
import { useAuth } from 'reactfire';
import { UploadSimple } from '@phosphor-icons/react';

const { Title, Text } = Typography;
const { Dragger } = Upload;

interface ColumnMappingModalProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: (values: any) => void;
  loading?: boolean;
  stripeCustomers: any[];
}

const ColumnMappingModal: React.FC<ColumnMappingModalProps> = ({
  open,
  onCancel,
  onConfirm,
  loading = false,
  stripeCustomers
}) => {
  const [form] = Form.useForm();
  const [fileHeaders, setFileHeaders] = useState<string[]>([]);
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const [fileData, setFileData] = useState<any[]>([]);
  const auth = useAuth();

  // Reset state when modal closes
  useEffect(() => {
    if (!open) {
      setFileHeaders([]);
      setFileData([]);
      form.resetFields();
    }
  }, [open, form]);

  const handleFileUpload = async (file: File) => {
    setIsProcessingFile(true);
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await fetch(
        `${process.env.REACT_APP_INTERNAL_API_URL}/processfilesimportdata`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${await auth.currentUser?.getIdToken()}`
          },
          body: formData
        }
      );

      if (!response.ok) {
        throw new Error('Error al procesar el archivo');
      }

      const result = await response.json();
      
      if (!result?.data) {
        throw new Error('No se recibieron datos del servidor');
      }

      // Extract headers from the first row
      const headers = result.data[0].map((cell: any) => cell.value);
      setFileHeaders(headers);
      setFileData(result.data);
      message.success('Archivo procesado correctamente');
    } catch (error: any) {
      console.error('Error processing file:', error);
      message.error(error?.message ?? 'Error al procesar el archivo');
    } finally {
      setIsProcessingFile(false);
    }
  };

  const handleOk = () => {
    form.validateFields().then(values => {
      // Match the data based on email addresses
      const matchedData = fileData.slice(1).map((row: any[]) => {
        const rowData = row.reduce((acc: any, cell: any, index: number) => {
          acc[fileHeaders[index]] = cell?.value ?? '';
          return acc;
        }, {});

        const matchingCustomer = stripeCustomers.find(
          customer => customer.email === rowData[values.email]
        );

        return {
          ...rowData,
          stripeCustomerId: matchingCustomer?.id,
          matched: !!matchingCustomer,
          tax_id: rowData[values.tax_id],
          legal_name: rowData[values.legal_name],
          zip: rowData[values.zip],
          tax_system: rowData[values.tax_system]
        };
      });

      onConfirm({
        mappings: values,
        matchedData
      });
    });
  };

  const handleCancel = () => {
    setFileHeaders([]);
    setFileData([]);
    form.resetFields();
    onCancel();
  };

  const uploadProps = {
    beforeUpload: (file: File) => {
      const isExcelOrCSV = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                          file.type === 'application/vnd.ms-excel' ||
                          file.type === 'text/csv';
      if (!isExcelOrCSV) {
        message.error('Solo se permiten archivos Excel o CSV');
        return false;
      }
      handleFileUpload(file);
      return false;
    },
  };

  return (
      <Modal
          title={
              <Title level={4} style={{ margin: 0 }}>
                  Confirma los campos
              </Title>
          }
          open={open}
          onCancel={handleCancel}
          onOk={handleOk}
          okText="Continuar"
          cancelText="Cancelar"
          width={800}
          okButtonProps={{
              className: 'btn-primary',
              disabled: !fileHeaders.length,
          }}
          cancelButtonProps={{
              className: 'btn-secondary',
          }}
          confirmLoading={loading}
      >
          <div style={{ marginBottom: '24px' }}>
              <Text type="secondary">
                  Sube un archivo Excel o CSV con la información fiscal de tus clientes. Los campos deben coincidir con
                  los datos de Stripe usando el email como identificador.
              </Text>
          </div>

          <Dragger {...uploadProps} style={{ margin: '5px', padding: '5px' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <UploadSimple size={22} />
                  <span className="p-small-regular">
                      Haz clic o arrastra el archivo aquí
                      <br />
                      Solo archivos Excel (.xlsx) o CSV
                  </span>
              </div>
          </Dragger>

          {isProcessingFile && (
              <div style={{ textAlign: 'center', marginBottom: '24px' }}>
                  <Spin size="large" />
              </div>
          )}

          {fileHeaders.length > 0 && (
              <Form form={form} layout="vertical">
                  <Form.Item
                      label={
                          <>
                              Email <span style={{ color: '#ff4d4f' }}>*</span>
                          </>
                      }
                      name="email"
                      rules={[{ required: true, message: 'Por favor selecciona el campo de email' }]}
                  >
                      <Select placeholder="Selecciona el campo">
                          {fileHeaders.map((header, index) => (
                              <Select.Option key={index} value={header}>
                                  {header}
                              </Select.Option>
                          ))}
                      </Select>
                  </Form.Item>

                  <Form.Item
                      label={
                          <>
                              RFC <span style={{ color: '#ff4d4f' }}>*</span>
                          </>
                      }
                      name="tax_id"
                      rules={[{ required: true, message: 'Por favor selecciona el campo de RFC' }]}
                  >
                      <Select placeholder="Selecciona el campo">
                          {fileHeaders.map((header, index) => (
                              <Select.Option key={index} value={header}>
                                  {header}
                              </Select.Option>
                          ))}
                      </Select>
                  </Form.Item>

                  <Form.Item
                      label={
                          <>
                              Nombre o razón social <span style={{ color: '#ff4d4f' }}>*</span>
                          </>
                      }
                      name="legal_name"
                      rules={[{ required: true, message: 'Por favor selecciona el campo de nombre' }]}
                  >
                      <Select placeholder="Selecciona el campo">
                          {fileHeaders.map((header, index) => (
                              <Select.Option key={index} value={header}>
                                  {header}
                              </Select.Option>
                          ))}
                      </Select>
                  </Form.Item>

                  <Form.Item
                      label={
                          <>
                              código postal <span style={{ color: '#ff4d4f' }}>*</span>
                          </>
                      }
                      name="zip"
                      rules={[{ required: true, message: 'Por favor selecciona el campo de código postal' }]}
                  >
                      <Select placeholder="Selecciona el campo">
                          {fileHeaders.map((header, index) => (
                              <Select.Option key={index} value={header}>
                                  {header}
                              </Select.Option>
                          ))}
                      </Select>
                  </Form.Item>

                  <Form.Item
                      label={
                          <>
                              Régimen Fiscal <span style={{ color: '#ff4d4f' }}>*</span>
                          </>
                      }
                      name="tax_system"
                      rules={[{ required: true, message: 'Por favor selecciona el campo de régimen fiscal' }]}
                  >
                      <Select placeholder="Selecciona el campo">
                          {fileHeaders.map((header, index) => (
                              <Select.Option key={index} value={header}>
                                  {header}
                              </Select.Option>
                          ))}
                      </Select>
                  </Form.Item>
              </Form>
          )}
      </Modal>
  )
};

export default ColumnMappingModal; 