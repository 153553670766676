import React, { useState } from 'react';
import { Typography, Button, Switch, Space, message } from 'antd';
import { httpsCallable } from 'firebase/functions';
import { getFunctions } from 'firebase/functions';
import { useSelector } from 'react-redux';

const { Title, Paragraph, Text } = Typography;

interface InvoiceConfigStepProps {
  onBack: () => void;
  stripePayments: any[];
  matchedCustomers: any[];
  onClose: () => void;
  isAPreviousMonth: boolean;
}

const InvoiceConfigStep: React.FC<InvoiceConfigStepProps> = ({
  onBack,
  stripePayments,
  matchedCustomers,
  isAPreviousMonth,
  onClose
}) => {
  const [autoGenerateInvoices, setAutoGenerateInvoices] = useState(true);
  const [sendByEmail, setSendByEmail] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { team } = useSelector((state: any) => state.team);

  const handleGenerateInvoices = async () => {
    setIsLoading(true);
    try {
      const addDocumentsFunction = httpsCallable(getFunctions(), 'adddocumentstostripeevents');
      
      // Process payments with metadata based on switches
      const processedPayments = stripePayments.map(payment => ({
        ...payment,
        metadata: {
          ...payment.metadata,
          avoidAutomaticInvoice: !autoGenerateInvoices,
          avoidEmails: !sendByEmail
        }
      }));

      // Upload both customers and payments in sequence
      await addDocumentsFunction({
        resources: matchedCustomers,
        resource_type: 'clients',
        teamId: team?.id
      });

      await addDocumentsFunction({
        resources: processedPayments,
        resource_type: 'payments',
        teamId: team?.id
      });

      message.success('Datos importados correctamente');
    } catch (error) {
      console.error('Error generating invoices:', error);
      message.error('Error al importar los datos');
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <div style={{ maxWidth: '100%' }}>
      <div style={{ marginBottom: '32px' }}>
        <Title level={2}>Genera automáticamente facturas para tus pagos importados</Title>
        <Paragraph style={{ color: 'rgba(0, 0, 0, 0.65)' }}>
          Genera automáticamente facturas en gigstack para los pagos que importas desde Stripe. En caso de no contar con la información fiscal, crearemos un portal de auto-factura
        </Paragraph>
      </div>

      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            <Switch 
              checked={autoGenerateInvoices}
              onChange={setAutoGenerateInvoices}
            />
            <Text>Generar automáticamente facturas para los pagos importados</Text>
          </div>
          {autoGenerateInvoices && isAPreviousMonth && (
            <span className='p-small-regular neutral-3'>Generaremos facturas para los pagos del mes en curso.</span>
          )}
        </div>

        <div>
          <div style={{ marginBottom: '8px' }}>
            <Text strong>¿Quieres enviar las facturas por correo?</Text>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            <Switch 
              checked={sendByEmail}
              onChange={setSendByEmail}
            />
            <Text>Enviar las facturas/portales por correo electrónico</Text>
          </div>
        </div>
      </Space>

      <div style={{ 
        marginTop: '48px',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '12px'
      }}>
        <Button className="btn-secondary" onClick={onBack}>Anterior</Button>
        <Button
          className="btn-primary"
          onClick={handleGenerateInvoices}
          disabled={!stripePayments.length}
          loading={isLoading}
        >
          Finalizar
        </Button>
      </div>
    </div>
  );
};

export default InvoiceConfigStep; 