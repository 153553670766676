import { ArrowLeft, CurrencyDollar, Robot, Swap } from '@phosphor-icons/react'
import { Button, Col, Row, Typography, message, Modal, Alert, Space } from 'antd'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { usePostHog } from 'posthog-js/react'
import styled from 'styled-components'
import { GetTeamHelper } from '../context/databaseContextHelpers'
import SwitchHolder from '../components/Forms/SwitchHolder'
import { trackEvent } from '../analytics/helpers'
import StripeImportWorkflow from '../features/stripe-import/StripeImportWorkflow'
import { openModal, closeModal } from '../context/modalsSlice'
import { getFunctions, httpsCallable } from 'firebase/functions'
import moment from 'moment'

const Container = styled.div`
    padding: 24px;
    max-width: 1200px;
    margin: 0 auto;
`

const Header = styled.div`
    margin-bottom: 24px;
`

const StatusTag = styled.div`
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 16px;
    background-color: var(--green-2);
    color: var(--green-8);
`

const Section = styled.div`
    margin-bottom: 24px;
`

const SectionContent = styled.div`
    background: var(--white);
    border-radius: 8px;
    padding: 16px;

    .switch-holder {
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
    }
`

interface PaymentWithoutAutomation {
    id: string;
}

interface PaymentsWithoutAutomationResult {
    livemodeCount: number;
    testmodeCount: number;
    payments: PaymentWithoutAutomation[];
}

const StripeConfigPage = () => {
    const navigate = useNavigate()
    const posthog = usePostHog()
    const fs = getFirestore()
    const functions = getFunctions()
    const dispatch = useDispatch()
    const { team } = useSelector((state: any) => state.team)
    const stripeImportModalVisible = useSelector((state: any) => state.modals.stripeImportModalVisible)

    const [automaticInvoicing, setAutomaticInvoicing] = useState(team?.stripe?.automaticInvoicing ?? false)
    const [automaticInvoicingTest, setAutomaticInvoicingTest] = useState(
        team?.stripe?.onlyAutomaticInvoicingOnTestMode ?? false,
    )
    const [useStripeExchangeRate, setUseStripeExchangeRate] = useState(team?.defaults?.useStripeExchangeRate ?? false)
    const [convertPaymentsToCurrency, setConvertPaymentsToCurrency] = useState<string | null>(
        team?.defaults?.convertAllPaymentsToCurrency || null,
    )
    const [switchLoading, setSwitchLoading] = useState(false)
    const [paymentsWithoutAutomation, setPaymentsWithoutAutomation] = useState<PaymentsWithoutAutomationResult | null>(null)

    useEffect(() => {
        if (team && !team?.stripe?.completed) {
            navigate('/integrations')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team?.stripe?.completed])

    const handleOpenImportModal = () => {
        dispatch(openModal('stripeImportModalVisible'))
    }

    const handleCloseImportModal = () => {
        dispatch(closeModal('stripeImportModalVisible'))
    }

    const handleAutomatizePayments = async () => {
        if (!paymentsWithoutAutomation || !paymentsWithoutAutomation.payments) return
        
        setSwitchLoading(true)
        try {
            const handlePaymentsFunction = httpsCallable<any, any>(functions, 'hadlepaymentswithoutautomations')
            await handlePaymentsFunction({
                teamId: team?.id,
                paymentIds: paymentsWithoutAutomation.payments.map((p: PaymentWithoutAutomation) => p.id),
                action: 'update'
            })
            
            setPaymentsWithoutAutomation(null)
            message.success('Pagos actualizados correctamente')
        } catch (error: any) {
            message.error(error?.message ?? 'Ocurrió un error al actualizar los pagos')
        } finally {
            setSwitchLoading(false)
        }
    }

    return (
        <Container>
            <Button style={{display: 'flex', alignItems: 'center', gap: 8, padding: 0, marginBottom: 24, color: '#111827'}} type="text" onClick={() => navigate(-1)}>
                <ArrowLeft size={16} weight="regular" />
                <span style={{fontSize: 14, lineHeight: 20}}>Atrás</span>
            </Button>

            <Header>
                <Row align="middle" style={{marginBottom: 4}}>
                    <Typography.Text className="h1-bold" style={{marginRight: 8}}>Stripe</Typography.Text>
                    <StatusTag>Conectado</StatusTag>
                </Row>
                <Typography.Text className="p-small-regular neutral-2">Configuración</Typography.Text>
            </Header>

            <Row gutter={24} style={{margin: 0 -12}}>
                <Col span={12}>
                    <Section>
                        <Typography.Text className="p-large-bold" style={{marginBottom: 16, display: 'block'}}>Facturación Automática</Typography.Text>
                        <SectionContent>
                            <SwitchHolder
                                changeState={async () => {
                                    try {
                                        trackEvent(
                                            { name: 'stripe_automatic_invoicing', metadata: { active: !automaticInvoicing } },
                                            posthog,
                                        )
                                        
                                        if (!automaticInvoicing) {
                                            setSwitchLoading(true)
                                            
                                            const startDate = moment().startOf('month').valueOf()
                                            const endDate = moment().endOf('month').valueOf()
                                            
                                            const handlePaymentsFunction = httpsCallable<any, PaymentsWithoutAutomationResult>(functions, 'hadlepaymentswithoutautomations')
                                            const result = await handlePaymentsFunction({
                                                teamId: team?.id,
                                                action: 'count',
                                                startDate,
                                                endDate
                                            })
                                            
                                            if (result.data && (result.data.livemodeCount > 0 || result.data.testmodeCount > 0)) {
                                                setPaymentsWithoutAutomation(result.data)
                                            } else {
                                                setPaymentsWithoutAutomation(null)
                                            }
                                        }
                                        
                                        await updateDoc(doc(fs, 'teams', team?.id), {
                                            'stripe.automaticInvoicing': !automaticInvoicing,
                                        })
                                        setAutomaticInvoicing(() => !automaticInvoicing)
                                        await GetTeamHelper(team?.id, dispatch)
                                        message.success('Configuración actualizada')
                                    } catch (error) {
                                        message.error('Ocurrió un error al actualizar la configuración')
                                    } finally {
                                        setSwitchLoading(false)
                                    }
                                }}
                                switchState={automaticInvoicing}
                                loading={switchLoading}
                                icon={<Robot className="icon clickable" weight="regular" size={16} />}
                                title="Activar facturación automática"
                                description="Intentaremos facturar automáticamente tus ventas, si no es posible, crearemos un recibo de venta para que tu cliente genere su factura"
                            />

                            {paymentsWithoutAutomation && (
                                <div style={{ marginTop: '15px' }}>
                                    <Alert
                                        type="warning"
                                        message={
                                            <div>
                                                <Space direction="vertical">
                                                    <Typography.Text>
                                                        Hay {paymentsWithoutAutomation.livemodeCount + paymentsWithoutAutomation.testmodeCount} pagos del mes actual sin automatización.
                                                        {paymentsWithoutAutomation.livemodeCount > 0 && ` ${paymentsWithoutAutomation.livemodeCount} en modo producción.`}
                                                        {paymentsWithoutAutomation.testmodeCount > 0 && ` ${paymentsWithoutAutomation.testmodeCount} en modo prueba.`}
                                                    </Typography.Text>
                                                    <Typography.Text>
                                                        ¿Deseas automatizarlos?
                                                    </Typography.Text>
                                                </Space>

                                                <Row justify="end" style={{ marginTop: '10px', width: '100%' }}>
                                                    <Button 
                                                        size="small" 
                                                        type="primary" 
                                                        onClick={handleAutomatizePayments}
                                                        loading={switchLoading}
                                                    >
                                                        Automatizar pagos
                                                    </Button>
                                                </Row>
                                            </div>
                                        }
                                    />
                                </div>
                            )}

                            {automaticInvoicing && (
                                <SwitchHolder
                                    changeState={async () => {
                                        try {
                                            await updateDoc(doc(fs, 'teams', team?.id), {
                                                'stripe.onlyAutomaticInvoicingOnTestMode': !automaticInvoicingTest,
                                            })
                                            setAutomaticInvoicingTest(() => !automaticInvoicingTest)
                                            await GetTeamHelper(team?.id, dispatch)
                                            message.success('Configuración actualizada')
                                        } catch (error) {
                                            message.error('Ocurrió un error al actualizar la configuración')
                                        }
                                    }}
                                    switchState={automaticInvoicingTest}
                                    icon={<Robot className="icon clickable" weight="regular" size={16} />}
                                    title="Facturación automática solo en pagos de prueba"
                                    description="Solo automatzaremos los pagos que recibamos en modo test"
                                />
                            )}
                        </SectionContent>
                    </Section>

                    <Section>
                        <Typography.Text className="p-large-bold" style={{marginBottom: 16, display: 'block'}}>Monedas y tipos de cambio</Typography.Text>
                        <SectionContent>
                            <SwitchHolder
                                changeState={async (checked: boolean) => {
                                    try {
                                        await updateDoc(doc(fs, 'teams', team?.id), {
                                            'defaults.useStripeExchangeRate': checked,
                                            'defaults.convertAllPaymentsToCurrency': checked ? convertPaymentsToCurrency : null,
                                        })
                                        await GetTeamHelper(team?.id, dispatch)
                                        setUseStripeExchangeRate(checked)
                                        setConvertPaymentsToCurrency(checked ? convertPaymentsToCurrency : null)
                                        message.success('Configuración actualizada')
                                    } catch (error: any) {
                                        message.error(error?.message ?? 'Ocurrió un error al actualizar la configuración')
                                    }
                                }}
                                switchState={useStripeExchangeRate}
                                value={useStripeExchangeRate}
                                icon={<Swap className="icon clickable" weight="regular" size={16} />}
                                title="Usar el tipo de cambio proporcionado por Stripe"
                                description="Usaremos el tipo de cambio de Stripe para cada transacción"
                            />

                            <SwitchHolder
                                changeState={async (checked: boolean) => {
                                    try {
                                        const currency = checked ? 'mxn' : null
                                        if (!useStripeExchangeRate)
                                            return message.warning(
                                                'Para activar esta opción en tu cuenta debes tener activo el tipo de cambio de Stripe',
                                            )
                                        await updateDoc(doc(fs, 'teams', team?.id), {
                                            'defaults.convertAllPaymentsToCurrency': currency,
                                        })
                                        await GetTeamHelper(team?.id, dispatch)
                                        setConvertPaymentsToCurrency(currency)
                                        message.success('Configuración actualizada')
                                    } catch (error: any) {
                                        message.error(error?.message ?? 'Ocurrió un error al actualizar la configuración')
                                    }
                                }}
                                switchState={convertPaymentsToCurrency === 'mxn'}
                                disabled={!useStripeExchangeRate}
                                value={convertPaymentsToCurrency === 'mxn'}
                                icon={<CurrencyDollar className="icon clickable" weight="regular" size={16} />}
                                title="Convertir pagos en otras monedas a MXN"
                                description="Convertiremos automáticamente los pagos en otras monedas a pesos mexicanos usando el tipo de cambio de Stripe"
                            />
                        </SectionContent>
                    </Section>
                </Col>

                <Col span={12}>
                    <Section>
                        <Typography.Text className="p-large-bold" style={{marginBottom: 16, display: 'block'}}>Importación de Datos</Typography.Text>
                        <SectionContent>
                            <Typography.Text className="p-small-regular" style={{ marginBottom: '16px', display: 'block' }}>
                                Ya estás conectado con Stripe. Configuremos la exportación de pagos y la generación
                                automática de facturas.
                            </Typography.Text>
                            <Row style={{ marginTop: '16px' }} justify="end" gutter={8}>
                                <Col>
                                    <Button className="btn-primary" onClick={handleOpenImportModal}>
                                        Comenzar importación
                                    </Button>
                                </Col>
                            </Row>
                        </SectionContent>
                    </Section>
                </Col>
            </Row>

            <Modal
                open={stripeImportModalVisible}
                onCancel={handleCloseImportModal}
                footer={null}
                width={1200}
                destroyOnClose
            >
                <StripeImportWorkflow onClose={handleCloseImportModal} />
            </Modal>
        </Container>
    )
}

export default StripeConfigPage 