import { createSlice } from '@reduxjs/toolkit'

interface ModalsState {
    createServiceVisible: boolean
    substituteInvoiceModalVisible: boolean
    cancelInvoiceModalVisible: boolean
    stampInvoiceModalVisible: boolean
    addTaxModalVisible: boolean
    serviceModalVisible: boolean
    serviceSelectionVisible: boolean
    sendByEmailModalVisible: boolean
    templatesEmailsDrawerVisible: boolean
    createNewTemplateEmailModalVisible: boolean
    relationsDrawerVisible: boolean
    createClientModalVisible: boolean
    clientDefaultsModalVisible: boolean
    createClientDrawerVisible: boolean
    stripeBankInstructionsVisible: boolean
    stripeImportModalVisible: boolean
    service: any
    client: any
    paymentModalVisible: boolean
    relatedPaymentModalVisible: boolean
    cancelResourcesModalVisible: boolean
    relatedInvoicesModalVisible: boolean
    registerPaymentModalVisible: boolean
    payment: any
    invoiceModalVisible: boolean
    invoice: any
    receiptModalVisible: boolean
    editreceiptModalVisible: boolean
    receipt: any
    defaultsModalVisible: boolean
    recurringInvoiceModalVisible: boolean
    ViewAllAlgoliaDrawerVisible: boolean
    recurringInvoice: any
    recurringPaymentModalVisible: boolean
    recurringPayment: any
    integrationModalVisible: boolean
    integration: any
    teamModalVisible: boolean
    teamSelectionVisible: boolean
    createTeamVisible: boolean
    toEmail: any
    paymentConcilliationModalVisible: boolean
    confirmInvoiceModalVisible: boolean
    assignClientModalVisible: boolean
    settingsDrawerVisible: boolean
    approveModalVisible: boolean
    editWebhooksModalVisible: boolean
    searchResultContainerVisible: boolean
    paymentErrorModalVisible: boolean

    stripeModalVisible: boolean
    openpayModalVisible: boolean
    satModalVisible: boolean
    speiModalVisible: boolean
    zapierModalVisible: boolean
    airtableModalVisible: boolean
    googleSheetsModalVisible: boolean
    whmcsModalVisible: boolean
    hilosModalVisible: boolean
    downloadsModalVisible: boolean
    uploadThingsModalVisible: boolean
    conektaModalVisible: boolean

    autoinvoiceProcessModalVisible: boolean
    mergeClientsModalVisible: boolean

    createMassiveInvoicesModalVisible: boolean
    cancelMassiveInvoicesModalVisible: boolean
    confirmMassivePayInvoiceModalVisible: boolean
    massiveInvoiceStatusDrawerVisible: boolean
    editFailedMassiveInvoiceModalVisible: boolean

    addressModalVisible: boolean
}

const initialState: ModalsState = {
    createServiceVisible: false,
    addTaxModalVisible: false,
    ViewAllAlgoliaDrawerVisible: false,
    cancelInvoiceModalVisible: false,
    substituteInvoiceModalVisible: false,
    stampInvoiceModalVisible: false,
    serviceSelectionVisible: false,
    serviceModalVisible: false,
    createClientDrawerVisible: false,
    createClientModalVisible: false,
    clientDefaultsModalVisible: false,
    paymentModalVisible: false,
    relatedPaymentModalVisible: false,
    cancelResourcesModalVisible: false,
    relatedInvoicesModalVisible: false,
    registerPaymentModalVisible: false,
    invoiceModalVisible: false,
    receiptModalVisible: false,
    editreceiptModalVisible: false,
    defaultsModalVisible: false,
    recurringInvoiceModalVisible: false,
    recurringPaymentModalVisible: false,
    integrationModalVisible: false,
    teamModalVisible: false,
    teamSelectionVisible: false,
    createTeamVisible: false,
    sendByEmailModalVisible: false,
    templatesEmailsDrawerVisible: false,
    createNewTemplateEmailModalVisible: false,
    uploadThingsModalVisible: false,
    relationsDrawerVisible: false,
    stripeBankInstructionsVisible: false,
    stripeImportModalVisible: false,
    paymentErrorModalVisible: false,
    service: null,
    client: null,
    payment: null,
    invoice: null,
    receipt: null,
    toEmail: null,
    recurringInvoice: false,
    recurringPayment: null,
    integration: null,
    confirmInvoiceModalVisible: false,
    paymentConcilliationModalVisible: false,
    assignClientModalVisible: false,
    settingsDrawerVisible: false,
    approveModalVisible: false,
    editWebhooksModalVisible: false,
    searchResultContainerVisible: false,

    stripeModalVisible: false,
    openpayModalVisible: false,
    satModalVisible: false,
    speiModalVisible: false,
    zapierModalVisible: false,
    airtableModalVisible: false,
    googleSheetsModalVisible: false,
    whmcsModalVisible: false,
    hilosModalVisible: false,
    conektaModalVisible: false,

    downloadsModalVisible: false,
    autoinvoiceProcessModalVisible: false,
    mergeClientsModalVisible: false,
    createMassiveInvoicesModalVisible: false,
    cancelMassiveInvoicesModalVisible: false,
    confirmMassivePayInvoiceModalVisible: false,
    massiveInvoiceStatusDrawerVisible: false,
    editFailedMassiveInvoiceModalVisible: false,

    addressModalVisible: false,
}

export const modalsSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        openModal: (state: any, action) => {
            state[action.payload] = true
        },
        closeModal: (state: any, action) => {
            state[action.payload] = false
        },
        setElementSelected: (state: any, action) => {
            state[action.payload.element] = action.payload.data
        },
    },
})

export const { openModal, closeModal, setElementSelected } = modalsSlice.actions

export default modalsSlice.reducer
