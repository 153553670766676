import { Col, Divider, Row, Tabs, Typography, message } from 'antd'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { teamCompleteGettingStarted } from '../../helpers/helperFunctions'
import AppCard from '../components/Cards/CardApp'
import { GetTeamHelper } from '../context/databaseContextHelpers'
import { openModal } from '../context/modalsSlice'
import { integrations } from '../datasets/Integrations'
import DefaultsModal from '../modals/gettingStarted/DefaultsModal'
import SatModalGs from '../modals/gettingStarted/SatModalGettingStarted'
import { useNavigate } from 'react-router-dom'

function IntegrationsSettings() {
    const dispatch = useDispatch()
    const fs = getFirestore()
    const navigate = useNavigate()

    const { team } = useSelector((state: any) => state.team)

    const completeGettingStarted = teamCompleteGettingStarted(team)
    const [modalOpen, setModalOpen] = useState<null | string>(null)

    const handleOpenIntegrationModal = (modalKey: string, integrationId: string) => {
        const nextStep = completeGettingStarted.nextStep
        if (nextStep === 'integrations') {
            if (integrationId === 'stripe' && team?.stripe?.completed) {
                navigate('/stripeDetails')
                return
            }
            return dispatch(openModal(modalKey))
        }
        message.warning(
            nextStep === 'facturapi'
                ? 'Debes completar la integración con SAT'
                : 'Debes completar los defaults de tu cuenta',
        )
        return setModalOpen(nextStep)
    }

    const clasification = [
        {
            name: 'Todas las integraciones',
            id: 'all',
        },
        {
            name: 'Pagos',
            id: 'payments',
        },
        {
            name: 'Facturación',
            id: 'invoice',
        },
        {
            name: 'Comunicación',
            id: 'communication',
        },
    ]

    const AppsInfo = () => {
        const [showAll, setShowAll] = useState(false)

        const renderIntegrationCards = (category: string) => {
            const filteredIntegrations = integrations(team)
                .filter(
                    (integration) =>
                        (showAll || integration.clasification === category) &&
                        !(team?.integrationsBlocked ?? []).includes(integration.id),
                )
                .reduce(
                    (acc: { completed: any[]; incomplete: any[] }, integration) => {
                        integration.completed ? acc.completed.push(integration) : acc.incomplete.push(integration)
                        return acc
                    },
                    { completed: [], incomplete: [] },
                )

            const sortedIntegrations = [...filteredIntegrations.completed, ...filteredIntegrations.incomplete]

            return sortedIntegrations.map((integration: any) => (
                <Col key={integration.id} xs={24} lg={8}>
                    <AppCard
                        integration={integration}
                        onClick={() => {
                            handleOpenIntegrationModal(integration.modalKey, integration.id)
                        }}
                    />
                </Col>
            ))
        }

        const handleTabChange = (activeKey: string) => {
            if (activeKey === 'all') {
                setShowAll(true)
            } else {
                setShowAll(false)
            }
        }

        return (
            <Col xs={24}>
                <Tabs defaultActiveKey="payments" onChange={handleTabChange}>
                    {clasification.map((category) => (
                        <Tabs.TabPane tab={category.name} key={category?.id}>
                            <Row gutter={12}>{renderIntegrationCards(category.id)}</Row>
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </Col>
        )
    }

    return (
        <>
            <Typography className="h2-bold neutral-1">Integraciones</Typography>
            <Typography.Text className="p-base-regular neutral-3">
                Integra las aplicaciones que utilizas día con día y automatiza las acciones
            </Typography.Text>
            <Col xs={24}>
                <SatModalGs
                    isOpen={modalOpen === 'facturapi'}
                    onClose={() => setModalOpen(null)}
                    onStepCompletion={() => setModalOpen('defaults')}
                />
                <DefaultsModal
                    isOpen={modalOpen === 'defaults'}
                    onClose={async (step) => {
                        if (step === 6) {
                            await updateDoc(doc(fs, `teams/${team?.id}`), { 'defaults.completed': true })
                            GetTeamHelper(team?.id, dispatch)
                        }
                        setModalOpen(null)
                    }}
                    onStepCompletion={async () => {
                        await updateDoc(doc(fs, `teams/${team?.id}`), { 'defaults.completed': true })
                        GetTeamHelper(team?.id, dispatch)
                        setModalOpen(null)
                    }}
                />
                <div className="settingsContainer">
                    <Divider />
                    <AppsInfo />
                </div>
            </Col>
        </>
    )
}

export default IntegrationsSettings
